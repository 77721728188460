.container {
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    gap: 2rem;
  
    font-family: var(--main-body-font);
    font-size: 2rem;
    text-wrap: balance;
    /* text-align: left; */
  }

  .societyDescription {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    font-size: 2rem;
    /* line-height: 1.4em; */
    /* align-items: flex-start; */
    letter-spacing: -.02em;
    font-weight: 600;
    /* padding: 0 4rem; */
    max-width: 1200px;
    align-items:center;
    justify-content:center;
    /* margin: auto; */
    
  }
  
  .container1{
    display:flex;
    align-items:center;
    justify-content:space-between;
    /* margin:1rem; */
    gap: 2rem;
  }
  .left{
    display:flex;
    flex-direction: column;
    justify-content:left;
    gap: 2rem;
    align-items: left;
    text-align: left;
    /* width:50vw; */
    font-size: 1.5rem;
    /* line-height: 1.4rem;  */
    
  }
  .right{
    display:flex;
    width: 100%;
    font-size: .8rem;
    flex-direction: column;
    align-items: center;
    /* justify-content:right; */
    /* align-items: right; */
    text-align: right;
    gap: 1rem;
    /* line-height: 1.8rem; */
  }

  .right .heading {
    font-size: 1.5rem;
    text-align: center;
  }

  .subright{
    display:flex;
    flex-direction: row;
    text-align: right;
  }
  .subright1{
    display:flex;
    flex-direction: column;
    /* text-align: left; */
    
  }

  .links {
    gap: .2rem;
  }

  .others{
    color:black;
    justify-content: center;
    align-items:center;
    font-size: 1rem;
    border-radius: 2rem;
    font-weight: 700;
    background-color: #ccc2f4;
    padding: .4rem 1rem;
    /* padding-top: 0.1rem; */
    margin:2px;
    text-align: center;
    transition: all .5s ease;
  }

  .others:hover {
    background: var(--midnight-blue);
    color: white;
    box-shadow: 3px 3px var(--bright-pink);
    translate: -3px -3px;
  }

  .color1{
    color: #6728ce;
    /* margin-left:1rem; */
    text-align: right;
  }
  .color2{
    color: #ffba00
  }
  .color3{
    color: #1b99e5
  }

  .highlights{
    /* margin:1rem;
    margin-left:4rem; */
    font-size: 2rem;
    text-wrap: nowrap;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    /* display: none; */
  }
  .links{
    display:flex;
    flex-direction: column;
  }
  .societyDescription p:first-child {
    /* font-size: 1.8rem;
    line-height: 1.4em; */
    /* font-family: 'Nunito', sans-serif; */
  }
  
  .societyDescription p {
    /* font-size: 1.8rem;
    line-height: 1.4em; */
    /* font-family: 'Nunito', sans-serif; */
  }
  
  .photos {
  
    /* border: solid var(--primary-color-lighter) 3px; */
    border-radius: var(--border-radius);
    padding: 1rem 0;
    /* width: 80%; */
    margin: auto;
  
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(3, 1fr);
    /* grid-template-rows: repeat(3, 1fr); */
  }
  
  .photos > .photo {
    overflow: hidden;
    border-radius: var(--border-radius);
    transition: all .3s ease;
  }
  
  .photos > .photo:hover {
    scale: .9;
    /* transform: scale(.98) translate(-.5rem, -.5rem); */
    /* box-shadow: .7rem .7rem 10rem #272264; */
    /* box-shadow: .3rem .3rem var(--primary-color), .4rem .4rem var(--secondary-color), .7rem .7rem var(--primary-color); */
  }
  
  .photo:nth-child(1):hover {
    box-shadow: .7rem .7rem 10rem var(--primary-color);
  }
  .photo:nth-child(2):hover {
    box-shadow: .7rem .7rem 10rem var(--secondary-color);
  }
  .photo:nth-child(3):hover {
    box-shadow: .7rem .7rem 10rem var(--fire-brick);
  }
  
  .photos > .photo:nth-child(1) {
    grid-column: 1 / 3;
    grid-row: 1 / 3;
  }
  .photos > .photo:nth-child(2) {
    grid-column: 3 / 4;
  }
  .photos > .photo:nth-child(3) {
    grid-column: 3 / 4;
  }
  
  .photos > .photo > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .workHighlight__content {
    padding: 2rem 0;
    max-width: 1000px;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }
  
  .partnerships {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
    height: 20rem;
    overflow-x: hidden;
    position: relative;
  }
  
  .partnerships::after {
    content: '';
    position: absolute;
    width: 100vw;
    height: 100%;
    top: 0;
    left: -2rem;
    pointer-events: none;
    /* background: red; */
  
    background: linear-gradient(to right, #fff, #fff1, #fff0, #fff1, #fff);
  }
  
  .sponsor {
    /* width: 5rem; */
    height: 5rem;
  }
  
  .sponsor:last-child {
    margin-right: 5rem;
  }
  
  @media only screen and (max-width: 860px){
    .workHighlight__content {
      grid-template-columns: 1fr;
    }
    .heading{
        font-size:1rem;
    }
    .container1 {
      flex-direction: column;
    }
    .left{
        /* width:58vw; */
        /* font-size: 1rem;
        line-height: 1.2rem;  */
      }
    .right{
        font-size: 6rem;
        line-height: 1.2rem;
        margin-left:4rem;
      }
      .highlights{
        width: 100%;
        /* padding: 2rem 0; */
        /* font-size:1rem;
        line-height:1.5rem;
        margin-left:1.7rem; */
        text-align: left;
        align-items: flex-start;
      }
      .others{
        font-size: 1rem;
      }
  }
  
  @media only screen and (max-width: 500px){
    .societyDescription {
      font-size: 1rem;
      padding: 0;
      align-items:center;

    }
  
    .photos > div:nth-child(1) {
      grid-column: 1 / -1;
      /* grid-row: 1 / 3; */
    }
    .photos > div:nth-child(2) {
      grid-column: 1 / -1;
    }
    .photos > div:nth-child(3) {
      grid-column: 1 / -1;
    }
    .heading{
        font-size:1rem;
        text-align:center;
    }

    .container1{
      display:flex;
      flex-direction:column;
      align-items:center;
      justify-content:center;
      /* margin:1rem; */
    }
    
    .left{
        /* width:80vw; */
        /* align-items:center; */
        /* justify-content: center; */
        /* font-size: 0.8rem;
        line-height: 1rem;
        text-align:center; */
        font-size: 1rem;
      }

      .highlights {
        font-size: 1.5rem;
      }
    

      .right{
        flex-direction: column;
        /* justify-content:right; */
        /* align-items: right; */
        /* text-align: right; */
        margin-left:0rem;
        font-size: 1.5rem;
        line-height: 1.8rem;
      }
      .subright{
        align-items: center;
        justify-content: center;
      }
    .others{
        /* font-size:0.6rem; */
        text-align:center;
        text-wrap: nowrap;
    }
  }