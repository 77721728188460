.partnership{
    display:flex;
    flex-direction: column;
}

.images{
    display:grid;
    grid-template-columns: repeat(3, 1fr);
    place-content: space-around;
    gap: 3rem;
    max-width: 1200px;
    margin:auto;
}

.sponsor img{
    width:100%;
    height:100%;
    object-fit: contain;
}

.sponsor{
    height:8rem;
}

.text{
    display:flex;
    justify-content:space-between;
    gap:2rem;
    margin-bottom:2rem;
    padding:1rem;
}

.left{
    display: flex;
    flex-direction: column;
    max-width: 800px;
    text-align: left;
    color:black;
    font-size:1.4rem;
    text-wrap: balance;
    gap:0.7rem;
}
.left h1{
    font-family: var( --stylized-body-font-2);
    color:var(--midnight-blue);
    font-size:2.5rem;
}

.right{
    display:flex;
    align-items:center;
    justify-content:space-between;
    gap:1rem;
    font-size:2.5rem;
    font-weight: 500;
}
.right h1{
    color:var( --purple);
    font-size: inherit;
}
.right p{
    display:flex;
    color:var(  --grey);
}

@media only screen and (max-width:768px){
    .images{
        grid-template-columns: repeat(2, 1fr);
    }
    .text{
        flex-direction: column;
    }
    .right{
        justify-content: flex-start;
    }
}
@media only screen and (max-width:600px){
    .images{
        grid-template-columns: 1fr;
    }
}