@import url('https://fonts.googleapis.com/css2?family=Macondo&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope&display=swap');

*.slider {
    font-family:"Macondo";
}
*.slider p{
    font-size:2vw;
    font-family:"Macondo";
}
.name{
    margin-top:-2vw;
}
.slider {
    display:flex;
    justify-content:space-evenly;
    padding:2vw;
    align-items:center;
    padding-top:10vw;
    padding-bottom:10vw;
}
.card{
    background-color:#f1e9bb;
    display:flex;
    justify-content:space-evenly;
    align-items:center;
    width:70vw;
    border-radius:2vw;
}
.circle {
    height:15vw;
    width:15vw;
    background:grey;
    background-size:100%;
    border-radius: 50%;
    display:flex;
    flex-direction:column;
    margin:3vw;
}
.text {
    width:45vw;
    flex-wrap:wrap;
    margin:3vw;
}
.leftButton {
    background:url('left.png');
    background-size:cover;
    width:2vw;
    height:2vw;
}
.rightButton {
    background:url('right.png');
    background-size:cover;
    width:2vw;
    height:2vw;
}
.button {
    background-size:2vw 2vw;
    background-position:center;
    background-attachment:fixed;
    background-repeat:no-repeat;
    display:flex;
    justify-content:space-evenly;
    align-items:center;
    height:8vw;
    width:10vw;
}
.button h1 {
    font-family:Manrope;
    font-size:10vw;
}

@media (max-width:1200px){
    *.slider p{
        font-size:2.5vw;
    }
    .card{
        width:75vw;
        height:50vw;
        flex-wrap:wrap;
        border-radius:5vw;
    }
    .circle {
        height:28vw;
        width:28vw;
    }
    .text {
        padding-bottom:3vw;
    }
    .rightbutton {
        width:3vw;
        height:auto;
    }
    .leftbutton {
        width: 3vw;
        height: auto;
    }
}

@media (max-width:1200px) and (min-width:527px){
    .card{
        visibility: hidden;
    }
}

@media (max-width:500px) {
    *.slider p{
        font-size:3vw;
    }
    .card{
        border-radius:5vw;
        width:75vw;
        height:60vw;
        flex-wrap:wrap;
    }
    .circle {
        height:30vw;
        width:30vw;
    }
    .text {
        padding-bottom:3vw;
    }
    .rightbutton {
        width:3vw;
        height:auto;
    }
    .leftbutton {
        width: 3vw;
        height: auto;
    }
}