.testimonials {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  /* margin-top: 5rem; */
  gap: 2rem;
  /* background: red; */
  position: relative;
  overflow-x: hidden;
}

.testimonials::after {
  content: '';
  position: absolute;
  width: 100vw;
  height: 100%;
  top: 0;
  left: -2rem;
  pointer-events: none;
  /* background: red; */

  background: linear-gradient(to right, #fff, #fff1, #fff0, #fff1, #fff);
}

.heading {
  font-size: 4rem;
  margin: 0;
  font-weight: 700;
  letter-spacing: -.02em;
  position: relative;
  z-index: 5;
  font-family: var(--stylized-body-font-2);
  color: var(--primary-color);
  /* text-align: left; */
}

.testimonial_card {
  /* background: red; */
  border: solid 2px #8884;
  display: flex;
  /* height: 30vh; */
  width: 30vw;
  aspect-ratio: 16/9;
  /* height: 30vh; */
  /* width: fit-content; */
  flex-shrink: 0;
  flex-direction: column;
  padding: 2rem 1rem;
  text-wrap: balance;
  gap: 1.5rem;
  /* background: var(--primary-color); */
  border-radius: var(--border-radius);
  transition: all .3s ease;
}

.testimonial_card:hover {
  background: var(--primary-color);
}

.testimonial_card:hover .testimonial_card__quote {
  color: white;
  font-weight: 600;
}

.testimonial_card:hover .testimonial_card__author {
  color: var(--mimi-pink);
  /* font-weight: 600; */
}

.testimonial_card__quote {
  font-size: 1.4rem;
  line-height: 1.4em;
  font-weight: 600;
  /* color: white; */
  /* color: var(--primary-color); */
  color: var(--primary-color);
}

.testimonial_card__author {
  display: flex;
  flex-direction: column;
  color: black;
  /* color: var(--midnight-blue); */
  /* align-self: flex-start; */
  /* justify-content: center; */
  /* gap: 1.5rem; */
}
.testimonial_card__name {
  font-size: 1.5rem;
  font-weight: 600;
}

.testimonial_card:last-child {
  margin-right: 2rem;
  /* background-color: aquamarine; */
}

@media only screen and (max-width: 1000px) {
  .testimonial_card {
    width: 50vw;
  }
}

@media only screen and (max-width: 600px) {
  .testimonial_card {
    width: 80vw;
  }
}