.galleryWrap{
    display:flex;
    flex-wrap: wrap;
    gap:10px;
    align-items: center;
    max-width: 620px;
    margin:0 auto;
}
.galleryWrap .single{
    width: 200px;
    cursor:pointer;
}
.galleryWrap .single img{
    max-width: 100%;
}
.galleryWrap.single img:hover{
    transform:scale(100);
}