.container {
  border-radius: 25px;
  overflow: hidden;
}

.image_container {
    height: 75vh;
    width: 100%;
    position: relative;
    /* background: yellow; */
  }
  
  .image_container img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: relative;
    /* background: yellow; */
}

@media only screen and (max-width: 800px) {
  .image_container {
    height: 40vh;
  }
}