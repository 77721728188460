@import url('https://fonts.googleapis.com/css2?family=Josefin+Slab:wght@600&display=swap');

.teams{
    margin:0;
    display:flex;
    flex-direction:column;
    justify-content:space-evenly;
    padding: 0 2rem;
}

.title {
    /* background: linear-gradient(#67B196, #67B196 60%, white ); */
    display:flex;
    justify-content: space-evenly;
    align-items:center;
    color: black;
    font-size: 3rem;
    padding: 2rem 0;
    font-family: var(--stylized-body-font-2);
}

.trenure {
    display:flex;
    justify-content:flex-end;
    margin-top:3px;
}
.trenure p {
    background:#FFBA08;
    border-radius:1.8vw;
    box-shadow: 2px 2px 5px grey;
    align-items:center;
    text-align:center;
    padding-top:0.5vw;
    padding-bottom:0.5vw;
    padding-right:1vw;
    padding-left:1vw;
    margin-right:3vw;
    size:auto;
}

.card {
    display:flex;
    flex-direction:column;
    justify-content:space-evenly;
    align-items:center;
    border-radius:10px;
    background-color:transparent;
    gap: 1.5rem;
}

.circle {
    height:15vw;
    aspect-ratio: 1;
    background: white;
    background-size:100%;
    background-position: center center;
    border-radius: 50%;
    text-align:center;
    display:flex;
    flex-direction:column;
    justify-content:flex-end;
    margin-top:10px;
    margin-bottom:10px;
    overflow:hidden;
}

.circle img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.items {
    display:flex;
    justify-content:space-evenly;
    flex-wrap:wrap;
}

.portfolio {
    text-align:center;
    display:flex;
    flex-direction:column;
    justify-content:space-evenly;
    padding: 2.5rem 0;
    gap: 2rem;
}

.portfolio h2 {
    font-size: 2.5rem;
    font-family: var(--stylized-body-font-2);
}

.card p {
    font-size: 1.5rem;
    font-weight: 600;
    width: 30ch;
    text-wrap: wrap;
}

@media (max-width: 600px) {
    .trenure{
        font-size:0.8rem;
    }

    .card p {
        font-size: 0.7rem;
        font-weight: 700;
    }

    .circle {
        height: 30vw;
        aspect-ratio: 1;
    }

    .card {
        gap: .5rem;
    }
}