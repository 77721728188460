@property --gradient-angle {
	syntax: '<angle>';
	initial-value: 0deg;
	inherits: false;
}

.container {
	--gradient-color-1: var(--bright-blue);
	--gradient-color-2: var(--bright-pink);

	width: calc(100 * var(--vw));
	height: 95vh;
	background: #111;
	margin: 2rem 0;
	translate: -2rem;

	/* centering */
	display: grid;
	place-items: center;
	position: relative;

	overflow-x: hidden;
}

/* .tiltContainer {
    width: 100%;
    height: 100%;
} */

.banner {
	height: 70vh;
	aspect-ratio: 1.4285714286;
	background-size: cover;
	border-radius: var(--border-radius);
	position: relative;

	display: grid;
	place-items: center;
}

.banner img {
	position: absolute;
	width: 100%;
	height: 100%;
	object-fit: contain;
	border-radius: inherit;
}

.banner::before,
.banner::after {
	content: '';
	position: absolute;
	inset: -0.5rem;
	border-radius: inherit;
	background: conic-gradient(
		from var(--gradient-angle),
		var(--gradient-color-1),
		var(--gradient-color-2),
		var(--gradient-color-1)
	);

	z-index: -1;
	animation: animate-grad 5s linear infinite;
}

.banner::after {
	filter: blur(4rem);
}

@keyframes animate-grad {
	from {
		--gradient-angle: 0deg;
	}
	to {
		--gradient-angle: 360deg;
	}
}

.title {
	position: absolute;
	top: 50%;
	left: 50%;
	translate: -50% -50%;
	color: white;
	font-size: 4rem;
	/* color: transparent; */
	font-family: var(--stylized-body-font-2);
	letter-spacing: 0.01em;
	font-weight: 400;
	text-shadow: 3px 3px var(--gradient-color-2);
}

/* .highlighted {
  color: var(--gradient-color-2);
} */

.marqueeText {
	--gap: 1rem;

	position: absolute;
	width: max-content;
	/* height: 5rem; */
	/* padding: 1rem 0; */
	display: flex;
	justify-content: flex-start;
	align-items: center;
	gap: var(--gap);
	height: 5rem;
	/* background: var(--gradient-color-2); */
	/* background: var(--gradient-color-1); */
	/* background: black; */
	/* background: var(--midnight-blue); */
	color: white;
	font-size: 1.4rem;
	font-family: var(--stylized-body-font-2);
}

.marqueeText:first-child {
	top: 0;
	left: 0;
	animation: marquee-ltr 60s linear infinite;
}

.marqueeText:nth-child(2) {
	bottom: 0;
	left: 0;
	animation: marquee-ltr 60s linear infinite;
}

.marqueeText span:last-child {
	margin-right: var(--gap);
}

@keyframes marquee-ltr {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(-50%);
	}
}

@media only screen and (max-width: 1000px) {
	.banner {
		width: 80vw;
		height: 50vh;
	}
	.title {
		font-size: 3rem;
	}
}

@media only screen and (max-width: 600px) {
	.container {
		height: 85vh;
	}
	.banner {
		width: 80vw;
		height: 50vh;
	}
	.title {
		font-size: 3rem;
		text-wrap: balance;
	}
}
