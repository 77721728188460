/* @import url('https://fonts.googleapis.com/css2?family=Macondo&display=swap'); */

.home {
    padding: 1rem 0;
    font-family: var(--main-body-font);
    /* background:url('homebackground.jpeg'); */
    background-size:cover;
    background-position:center;
    background-repeat:no-repeat;
}
/* .home p{
    font-size:2vw;
    font-family:"Macondo";
} */

.intro {
    /* padding-top:5vw;
    display:flex;
    justify-content:space-evenly;
    align-items:center;
    flex-wrap:wrap; */
    position: relative;
    display: grid;
    place-items: center;
    height: 75vh;
    /* overflow: hidden; */
    width: 100%;
}

.intro .carousal_container {
    height: 100%;
    width: 100%;
    /* background: yellow; */
}

.intro__content {
    position: absolute;
    width: 100%;
    height: 100%;
    /* background: #0007; */
    background: linear-gradient(to right, #000, #0000);
    border-radius: 25px;
    border: solid 5px var(--midnight-blue);

    padding: 0 5rem;
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    justify-content: space-between;
    color: white;
}

.intro__content__text {
    font-size: 4rem;
    font-weight: 500;
    width: 50%;
    font-family: var(--stylized-body-font-2);
    /* letter-spacing: .04em; */
    text-align: left;
    text-wrap: balance;
}

/* .splineModel {
    width: 70%;
    height: 90%;
    object-fit: cover;
    border: solid 3px red;
    object-position: center;
    border-radius: 25px;
    position: relative;
    z-index: 10;
} */

.moto {
    margin-left:5vw;
}
.hexagon {
    height:24vw;
    width:35vw;
    /* background:url('hexagon.png') no-repeat; */
    background-size:contain;
    background-position:center;
    display:flex;
    justify-content:space-evenly;
    flex-wrap:wrap;
    align-items:center;
    text-align:center;
}
.hexagon p{
    /* margin:1vw; */
    /* padding:3vw; */
    font-size: 4rem;
    color: white;
}

.map {
    height:31vw;
    width:39vw;
    background:url('map.png') no-repeat;
    background-size:contain;
    background-position:center;
    margin-right:5vw;
    background-position: center;
    position: relative;
    overflow: hidden; /* Ensure the overflow is hidden to clip the shadow */
  }
  
.shadow {
    filter: drop-shadow(5px 5px 10px rgba(0, 0, 0, 0.3)); /* Horizontal offset, vertical offset, blur radius, and color */
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 1000px){

    .intro__content {
        background: linear-gradient(to top, #000, #0000);
    }

    .intro__content__text {
        /* font-size: 2.5rem; */
        width: 100%;
        text-align: center;
    }
}

/* @media(max-width:1200px){
    .home{
        height:100vh;
    }
    .intro{
        padding-top:10vw;
    }
    .hexagon{
        width:45vw;
        height:39vw;
    }
    .map{
        padding-top:10vw;
        width:45vw;
        height:39vw;
    }
} */

@media screen and (max-width: 800px){
    .intro {
        height: 40vh;
    }
    .intro__content {
        padding: 0 1rem;
    }
    .intro__content__text {
        font-size: 2rem;
    }
}

@media (max-width:500px) {
    /* .home{
        height:auto;
    }
    .hexagon p{
        font-size:3vw;
    }
    .intro{
        padding-top:10vw;
    }
    .hexagon{
        width:75vw;
        height:53vw;
    }
    .map{
        padding-top:10vw;
        width:94vw;
        height:67vw;
    } */
}