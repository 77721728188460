/* *{
    margin: 0;
    padding: 0;
}

@property --gradient-angle{
    syntax:"<angle>";
    initial-value:0deg;
    inherits:false;
}

:root{
    --clr1:#FF005C;
    --clr2:#FF7A00;
    --clr3:#00C2FF;
} */

.eventsgrid, .previous_events_heading {
    /* background: #000; */
    max-width: 1200px;
    margin: auto;
}

.eventsgrid {
    padding: 2rem 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 45vh);
    gap: 1.75rem;
}

.eventsgrid > div:first-child {
    grid-column: 1 / -1;
}

.eventcard_container:first-child {
    grid-column: 1 / -1;
}

.events_card{
    width:fit-content;
    height:100%;
    position:relative;
    display:grid;
    place-items:center;
    justify-content: center;
    /* margin:5%; */
    /* background-color: black; */
    border-radius: var(--border-radius);
    background: linear-gradient(to right, #000, #0000);
    max-width:1000px;
    margin: auto;
    /* margin-top:5vh; */
    /* line-height:1.2em; */
}

/* @keyframes rotation{
    0%  {--gradient-angle:0deg;}
    100%{--gradient-angle:360deg;}
} */

/* .events_card::before,
.events_card::after{
    content:"";
    position:absolute;
    inset:-.5rem;
    z-index:-1;
    background:conic-gradient(
        from var(--gradient-angle),
        var(--clr1),
        var(--clr2),
        var(--clr3),
        var(--clr2),
        var(--clr1)
    );
    border-radius:inherit;
    animation:rotation 15s linear infinite
}

.events_card::after{
    filter:blur(2rem);
} */

/* .nefest24img{
    width:100%;
    height:100%;
    justify-self: center;
    align-self: center;
    border-radius: 25px;
} */

.event_content{
    position:absolute;
    width:100%;
    height:100%;
    background:linear-gradient(to top,#000,#0000);
    border-radius: var(--border-radius);
    padding:0 2rem;
    display:flex;
    flex-direction: column;
    align-items:center;
    justify-content:center;
    color:white;
    /* line-height:1.5em; */
}

.event_content_text{
    font-size:3rem;
    font-weight: 500;
    text-align:center;
    line-height: 1.1em;
    text-wrap:balance;
    font-family: var(--stylized-body-font-2);
    /* margin:5%; */
}

.green_box_text{
    font-family: var(--stylized-body-font-2);
    color:white;
    /* margin-bottom:9%; */
    background-color: white;
    border-radius:var(--border-radius);
    /* width:100%;
    height:100%; */
    /* margin-top:2vh; */
    padding: 1rem 1rem;
    display:flex;
    align-items: center;
    justify-self: center;
    justify-content: center;
    font-size: 1.2rem;
}

.green_box{
    /* justify-self: center; */
    /* margin-right:5%; */
    /* display: none; */
}

/* .coming_soon_text{
    font-family: 'Protest Riot';
    opacity: 0.7;
    margin-bottom:25%;
    font-size: 30px;
} */

@media screen and (max-width:940px){
    .event_content_text{
        font-size: 2.5rem;
    }
    .green_box_text{
        font-size: 1rem;
    }
}

@media screen and (max-width:700px){
    .event_content_text{
        margin-top:10%;
        font-size: 1rem;
    }
    .green_box_text{
        font-size: 0.5rem;
        height:75%;
    }
    .coming_soon_text{
        font-size:0.5rem;
    }
    .green_box_text{
        font-size:1rem;
    }
    .event_content_text{
        font-size:2rem;
    }
    .coming_soon_text{
        font-size:1rem;
    }
}

@media screen and (max-width:600px){
    .event_content_text{
        font-size:1.8rem;
    }
}


@media screen and (max-width:300px){
    .event_content_text{
        margin-top:20%;
        font-size: 0.6rem;
    }
    .green_box_text{
        font-size: 0.3rem;
        height:75%;
    }
    .coming_soon_text{
        font-size:0.3rem;
    }
}

.previous_events{
    height:100%;
    width:100%;
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    /* margin-top:5vh;
    margin-bottom:5vh; */
    max-width: 1000px;
    margin:auto;
}

.previous_events_title{
    color:black;
    font-size:3rem;
    margin-top:5vh;
    font-family: 'Protest Riot';
    margin-bottom:1vh;
}

.previous_events_text{
    color: black;
    text-align: center;
    font-size:1.3rem;
    justify-self:flex-start;
    display:flex;
    margin: auto;
    width:60vw;
    /* margin-bottom:5vh; */
}

/* .eventpair{
    max-width:1000px;
    margin:auto;
    gap:5%;
} */

@media screen and (max-width:700px){
    .previous_events{
        justify-content: center;
        align-items:center;
        margin-top:5vh;
    }
    .previous_events_text>p{
        font-size: 1rem;
        margin-block: 1rem;
    }
}

.fest23img{
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    margin:auto;
}

.EventCard{
    cursor: pointer;
    width:fit-content;
    height:100%;
    position:relative;
    display:flex;
    place-items:center;
    justify-content: center;
    /* background-color: black; */
    border-radius: 15px;
    background: linear-gradient(to right, #000, #0000);
    margin:3%;
    max-width:1000px;
    margin:auto;
    margin-top:5vh;
    margin-bottom:5vh;
    overflow: hidden;
}

.event_card_content{
    position:absolute;
    width:100%;
    height:100%;
    background:linear-gradient(to top,#000,#0000);
    border-radius:25px;
    padding:0 5rem;
    display:flex;
    align-items:center;
    justify-content:space-between;
    color:white;
    max-width:1000px;
    margin:auto;
}

.left_side{
    display:flex;
    align-items: flex-start;
    gap:1vh;
}

.event_card_title{
    position:relative;
    left:0;
    font-size:2.2rem;
    font-weight: 400;
    text-wrap:balance;
    font-family: 'Protest Riot';
    margin-top:0%;
    margin-bottom:0;
    max-width: 500px;
    /* margin-left:5%; */
}

.event_card_title>p{
    margin-top:5%;
    margin-bottom:1%;
    text-align: left;
}

.event_card_description{
    max-width:500px;
    margin-left:0%;
    font-size:1.1rem;
    line-height: 1.2em;
}

.green_boxes{
    position:absolute;
    left:0%;
    display:flex;
    justify-content: flex-start;
    width:40vw;
    margin-bottom:3%;
    margin-top:-8%;
    gap:8%;
}

.green_boxes>.green_box{
    /* margin:10%; */
    margin-bottom:10%;
}

.green_boxes>.green_box>.green_box_text{
    width:150%;
    height:60%;
    font-size: 1.2rem;
}

.green_boxes_one>.green_box_one>.green_box_text_one{
    width:150%;
    height:60%;
    font-size: 0.9rem;
}

.left_side{
    position:relative;
    border-radius:25px;
    padding:0 5rem;
    display:flex;
    flex-direction: column;
    align-items:flex-start;
    color:white;
    width:90vh;
    padding:0;
}

.logo_container{
    display:flex;
    height:50%;
    align-items: flex-end;
    /* margin-left:-5%; */
}

.festlogo{
    position:relative;
    width:10vw;
}

.event_card_title>p{
    text-align: left;
}

@media screen and (max-width:1000px) {
    .event_card_title{
        font-size: 2rem;
    }
    .event_card_title>p{
        text-align: left;
    }
    .event_card_description{
        font-size: 1rem;
        max-width: 450px;
    }
    .green_boxes>.green_box>.green_box_text{
        width:140%;
        height:60%;
        font-size: 1rem;
    }
}

@media screen and (max-width:700px){
    .event_card_title{
        font-size:1.5rem;
    }
    .event_card_description{
        font-size:0.8rem;
        max-width:400px;
    }
    .left_side{
        margin-left:-13%;
    }
    .green_boxes>.green_box>.green_box_text{
        width:140%;
        height:60%;
        font-size: 0.8rem;
    }
    .logo_container{
        margin-right:-10%;
    }
    .previous_events_title{
        font-size:1.5rem;
    }
    .previous_events_text{
        font-size:1rem;
    }
}

@media screen and (max-width:550px){
    .event_card_title{
        font-size:1.3rem;
    }
    .event_card_description{
        font-size:0.7rem;
        max-width:350px;
    }
    .left_side{
        margin-left:-20%;
    }
    .green_boxes>.green_box>.green_box_text{
        width:150%;
        height:60%;
        font-size: 0.6rem;
    }
    .logo_container{
        margin-right:-15%;
    }
}

@media screen and (max-width:500px){
    .event_card_title{
        font-size:1.2rem;
    }
    .event_card_description{
        font-size:0.6rem;
        max-width:300px;
    }
    .green_boxes>.green_box>.green_box_text{
        width:140%;
        height:60%;
        font-size: 0.6rem;
    }
    .logo_container{
        margin-right:-20%;
    }
}

@media screen and (max-width:460px){
    .event_card_title{
        font-size:1rem;
    }
    .event_card_description{
        font-size:0.5rem;
    }
    .left_side{
        margin-left:-30%;
    }
    .green_boxes>.green_box>.green_box_text{
        width:150%;
        height:60%;
        font-size: 0.4rem;
    }
    .logo_container{
        margin-left:5%;
    }
}

@media screen and (max-width:360px){
    .left_side{
        gap:15%;
    }
    .event_card_title{
        font-size:0.8rem;
    }
    .event_card_description{
        font-size:0.2rem;
    }
    .green_boxes>.green_box>.green_box_text{
        width:140%;
        height:80%;
        font-size: 0.5rem;
    }
    .green_boxes>.green_box{
        height:100%;
    }
    .logo_container{
        display:none;
    }
}

.eventpair{
    display:flex;
}

.EventCard_one{
    cursor: pointer;
    width:100%;
    position:relative;
    display:flex;
    place-items:center;
    justify-content: center;
    border-radius: 15px;
    background: linear-gradient(to right, #232222, #23222200);
    margin-top:5vh;
    margin-bottom:5vh;
    overflow: hidden;
}

.normal{
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content:space-between;
    gap:3%;
    position:absolute;
    left:3%;
}

.event_card_title_one{
    position:relative;
    left:0;
    font-size:2rem;
    font-weight: 400;
    text-wrap:balance;
    font-family: 'Protest Riot';
    padding:3%;
}

.event_card_description_one{
    width:fit-content;
    /* margin-left:-8%; */
    font-size: 1rem;
    max-width: 400px;
    padding:3%;
    line-height:1.2em;
}

.event_card_description_one>p{
    text-align: left;
}

.green_box_text_one{
    font-family: 'Protest Riot';
    color:white;
    margin-bottom:7%;
    background-color: lime;
    border-radius:25px;
    width:150%;
    height:100%;
    margin-top:2vh;
    display:flex;
    align-items: center;
    justify-self: center;
    justify-content: center;
    font-size: 2rem;
}

.green_boxes_one{
    display:flex;
    justify-content: flex-start;
    width:25vw;
    /* margin-left:-15%; */
    margin-bottom:3%;
    padding-left:3%;
}

.green_boxes_one>.green_box_one>.green_box_text_one{
    width:130%;
    height:60%;
    font-size: 0.9rem;
}

.green_box_one{
    justify-self: center;
    margin-right:10%;
}

.event_card_title_one>p{
    text-align: left;
}

@media screen and (max-width:1200px){
    .green_boxes_one>.green_box_one>.green_box_text_one{
        font-size: 0.9rem;
    }  
    .green_boxes_one{
        width:100%;
    }
    .event_card_title_one{
        font-size: 1.8rem;
        width:30vw;
    }
    .event_card_description_one{
        font-size: 0.8rem;
    }
}

@media  screen and (max-width:1000px){
    .event_card_title_one{
        font-size: 1.3rem;
    }
    .event_card_title_one>p{
        text-align: left;
        margin-top:1%;
        margin-bottom:1%;
    }
    .event_card_description_one{
        font-size: 0.8rem;
    }
    .green_boxes_one>.green_box_one>.green_box_text_one{
        width:150%;
        height:60%;
        font-size: 0.7rem;
    }
}

@media screen and (max-width:700px){
    .eventpair{
        display: block;
    }
    .EventCard_one{
        height:fit-content;
        max-height: 37vh;
    }
    .event_card_title_one{
        font-size:1.5rem;
        width:100%;
    }
    .event_card_description_one{
        font-size:0.8rem;
    }
    .green_boxes>{
        gap:5%;
    }
    .green_boxes_one>.green_box_one>.green_box_text_one{
        width:140%;
        height:60%;
        font-size: 0.8rem;
    }
}

@media screen and (max-width:550px){
    .eventpair{
        display: block;
    }
    .event_card_title_one{
        font-size:1.4rem;
        width:50vw;
        margin-top:1%;
        margin-bottom:3%;
    }
    .event_card_description_one{
        font-size:0.7rem;
    }
    .green_boxes>.green_box_text_one{
        font-size: 0.5rem;
    }
    .green_boxes_one>.green_box_one>.green_box_text_one{
        width:150%;
        height:60%;
        font-size: 0.6rem;
    }
    .green_boxes_one{
        width:40vw;
        gap:3%;
    }
}

@media screen and (max-width:500px){
    .event_card_description_one{
        font-size:0.7rem;
        max-width:300px;
    }
    .green_boxes_one>.green_box_one>.green_box_text_one{
        width:140%;
        height:60%;
        font-size: 0.6rem;
    }
}

@media screen and (max-width:460px){
    .event_card_title_one{
        font-size:1rem;
    }
    .event_card_description_one{
        font-size:0.5rem;
    }
    .green_boxes_one>.green_box_text_one{
        font-size: 0.5rem;
    }
    .green_boxes_one>.green_box_one>.green_box_text_one{
        width:150%;
        height:60%;
        font-size: 0.4rem;
    }
}
/*
@media screen and (max-width:420px){
    .event_card_title{
        font-size:0.8rem;
    }
    .event_card_description{
        font-size:0.4rem;
    }
    .left_side{
        margin-left:-90%;
    }
    .green_boxes>.green_box_text{
        font-size: 0.3rem;
    }
    .green_boxes>.green_box>.green_box_text{
        width:150%;
        height:60%;
        font-size: 0.45rem;
    }
    .logo_container{
        margin-left:-30%;
    }
    ._events_title{
        font-size:1.5rem;
    }
    .previous_events_text{
        font-size:1rem;
    }
}
*/

@media screen and (max-width:360px){
    .event_card_title_one{
        font-size:0.8rem;
    }
    .event_card_description_one{
        font-size:0.45rem;
    }
    .green_boxes_one>.green_box_one>.green_box_text_one{
        width:130%;
        height:50%;
        font-size: 0.5rem;
    }
    .logo_container{
        margin-left:-140%;
    }
    .previous_events_title_one{
        font-size:1rem;
    }
    .previous_events_text_one{
        font-size:0.7rem;
    }
}

@media only screen and (max-width: 1000px) {
    .eventsgrid {
      grid-template-columns: repeat(1, 1fr);
    }
  }