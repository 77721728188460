.container {
  display: flex;
  /* font-family: var(--main-body-font); */
  /* overflow: hidden; */
  width: max-content;
  align-items: center;
  height: max-content;
  /* gap: 2rem; */
  /* text-align: left; */
  position: relative;
  animation: scroll 28s linear infinite;
}

.container[data-gap="small"] {
  --gap: 1rem;
}

.container[data-gap="large"] {
  --gap: 5rem;
}

.container[data-reverse="true"] {
  animation: scroll-reverse 28s linear infinite;
}

.container:hover {
  animation-play-state: paused;
}

.container > .itemList {
  width: fit-content;
  display: flex;
  align-items: center;
  gap: var(--gap);
}

.container > .itemList:first-child {
  /* border: solid lime 2px; */
  /* animation: move1 5s linear infinite; */
}

.container > .itemList:nth-child(2) {
  /* border: solid red 2px; */
  /* animation: move2 5s linear infinite; */
}

@keyframes scroll {
	0% {
		transform: translate3d(0, 0, 0);
	}
	100% {
		transform: translate3d(-50%, 0, 0);
	}
}

@keyframes scroll-reverse {
	0% {
		transform: translate3d(-75%, 0, 0);
	}
	100% {
		transform: translate3d(-50%, 0, 0);
	}
}