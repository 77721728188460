.aboutus {
    background:url('aboutus.jpeg');
    background-size:cover;
    background-repeat:no-repeat;
    margin:0;
    display:flex;
    flex-direction:column;
    justify-content:space-evenly;
}

.title {
    height:8vw;
    /* background: linear-gradient(#67B196, #67B196 60%, white ); */
    display:flex;
    justify-content: space-evenly;
    align-items:center;
    color:white;
    font-size:1.8rem;
}

@media (max-width: 600px) {
    * h2{
        font-size:1rem;
    }
    .title{
        height:12vw;
        font-size:1.5rem;
    }
}
@media (max-width:150px){
    *p {
        font-size: 1vw;
    }
}

.content {
    display:flex;
    flex-direction:column;
    justify-content:space-evenly;
    text-align:center;
}
.content > * {
    margin:4vw;
}

.card {
    background:#D9D9D9;
    margin-top:1vw;
    margin-bottom:1vw;
    margin-left:2vw;
    margin-right:2vw;
}

.info {
    display:flex;
    justify-content:space-evenly;
    flex-wrap:wrap;
    align-items:center;
}
.infocard {
    height:20vw;
    width:40vw;
}

.vision {
    color:white;
    display:flex;
    flex-direction:column;
    justify-content:space-evenly;
    align-items:center;
}
.visioncard{
    height:20vw;
    width:60vw;
}

.mission {
    color:white;
    display:flex;
    flex-direction:column;
    justify-content:space-evenly;
    align-items:center;
}
.missioncard{
    height:20vw;
    width:60vw;
}

@media (max-width:600px){
    .card{
        width:80vw;
        margin-bottom:3vw;
    }

    .infocard{
        height:40vw;
    }
    .visioncard{
        height:80vw;
    }

    .missioncard{
        height:80vw;
    }
}