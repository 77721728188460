.container {
  padding: 4rem 0;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  font-family: var(--main-body-font);
  text-wrap: balance;
  /* text-align: left; */
}

.societyDescription {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  font-size: 1.8rem;
  line-height: 1.4em;
  align-items: flex-start;
  letter-spacing: -.02em;
  font-weight: 600;
  /* padding: 0 4rem; */
  max-width: 1000px;
  margin: auto;
}

.societyDescription p:first-child {
  /* font-size: 1.8rem;
  line-height: 1.4em; */
  /* font-family: 'Nunito', sans-serif; */
}

.societyDescription p {
  /* font-size: 1.8rem;
  line-height: 1.4em; */
  /* font-family: 'Nunito', sans-serif; */
}

.photos {

  /* border: solid var(--primary-color-lighter) 3px; */
  border-radius: var(--border-radius);
  padding: 1rem 0;
  /* width: 80%; */
  margin: auto;

  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(3, 1fr);
  /* grid-template-rows: repeat(3, 1fr); */
}

.photos > .photo {
  overflow: hidden;
  border-radius: var(--border-radius);
  transition: all .3s ease;
}

.photos > .photo:hover {
  scale: .9;
  /* transform: scale(.98) translate(-.5rem, -.5rem); */
  /* box-shadow: .7rem .7rem 10rem #272264; */
  /* box-shadow: .3rem .3rem var(--primary-color), .4rem .4rem var(--secondary-color), .7rem .7rem var(--primary-color); */
}

.photo:nth-child(1):hover {
  box-shadow: .7rem .7rem 10rem var(--primary-color);
}
.photo:nth-child(2):hover {
  box-shadow: .7rem .7rem 10rem var(--secondary-color);
}
.photo:nth-child(3):hover {
  box-shadow: .7rem .7rem 10rem var(--fire-brick);
}

.photos > .photo:nth-child(1) {
  grid-column: 1 / 3;
  grid-row: 1 / 3;
}
.photos > .photo:nth-child(2) {
  grid-column: 3 / 4;
}
.photos > .photo:nth-child(3) {
  grid-column: 3 / 4;
}

.photos > .photo > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.workHighlight__content {
  padding: 2rem 0;
  max-width: 1000px;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}

@media only screen and (max-width: 860px){
  .workHighlight__content {
    grid-template-columns: 1fr;
  }
  .heading{
    font-size:2.5rem;
  }
  
    .photos > .photo:nth-child(1) {
      grid-column: 1 / -1;
      /* grid-row: 1 / 3; */
    }
    .photos > .photo:nth-child(2) {
      grid-column: 1 / -1;
    }
    .photos > .photo:nth-child(3) {
      grid-column: 1 / -1;
    }
    .photo{
      aspect-ratio: 16/9;
    }
}

@media only screen and (max-width: 500px){
  .societyDescription {
    font-size: 1.2rem;
    padding: 0;
  }
}