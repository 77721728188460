.alumni {
    height:100vh;
    width:100vw;
    background:url('./papirotexture.jpeg');
    background-size:cover;
    background-position:center;
    background-repeat:no-repeat;
    filter:saturate(125%);
    text-align:center;
    display:flex;
    flex-direction:column;
    justify-content:space-evenly;
    align-items:center;
}

.cardbatch {
    display:flex;
    flex-direction:column;
    justify-content:space-evenly;
    align-items:center;
    border-radius:10px;
    background-color:transparent;
}

.circlebatch {
    height:200px;
    width:200px;
    background:white;
    background-size:100%;
    border-radius: 50%;
    text-align:center;
    display:flex;
    flex-direction:column;
    justify-content:flex-end;
    box-shadow: 3px 5px 8px rgb(50, 43, 43);
    margin-top:10px;
    margin-bottom:10px;
}

.circlebatch h2 {
    margin-top: 0;
    margin-bottom:10px;
    color:aqua;
}

.individuals {
    display:flex;
    flex-wrap: wrap;
    justify-content:space-evenly;
    width:100%;
}

.cardindividual{
    display:flex;
    align-items:center;
    background:tan;
    border-radius: 10px;
    margin:25px;
    max-width:max-content;
}

.circleindividual {
    height:20vw;
    width:18vw;
    border-radius:50%;
    background:White;
    margin:10px;
}

@media (max-width: 600px) {
    .circle{
        height:180px;
        width:140px;
    }
    .circleindividual {
        height:30vw;
        width:25vw;
        border-radius:50%;
        background:White;
        margin:10px;
    }
}

.intro {
    display:flex;
    flex-wrap:wrap;
    flex-direction:column;
    text-align:left;
    margin:10px
}