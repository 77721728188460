@import url('https://fonts.googleapis.com/css2?family=Macondo&display=swap');

.footer {
    /* font-family:"Macondo"; */
    display:flex;
    justify-content:space-between;
    width: 100%;
    /* height:56vw; */
    /* background: url('footerbg.png'); */
    background: #fff;
    /* background: var(--footer-bg); */
    background-size: contain;
    background-position: center;
    /* color: #fff; */
    color: black;
    flex-wrap: wrap;
    padding: 3rem 2rem;
}

.links {
    display:flex;
    flex-direction:column;
    gap: 1rem;
    font-weight: 700;
    /* flex-direction:column;   */
    /* padding-top:4vw;
    padding-bottom:2vw; */
}

.linksGroup {
    display:flex;
    padding: 0 1rem;

    gap: 1rem;
    font-size: 1.2rem;
}

.links h2{
    /* margin-bottom:3vw; */
}
.links p{
    /* margin-bottom:2vw; */
}

.links h2, .contacts h2 {
    font-size: 1.8rem;
    letter-spacing: .2rem;
    font-family: var(--stylized-body-font-2);
}

.contacts {
    display:flex;
    flex-direction:column;
    gap: 1rem;
    font-weight: 700;
    /* padding-top:4vw;
    padding-bottom:2vw; */
}
.contacts h2{
    /* margin-bottom:3vw; */
}
.contacts p{
    /* margin-bottom:2vw; */
}

@media (max-width:600px) {
    * p{
        /* font-size:2.2vw; */
        /* margin-bottom:1vw; */
    }
    * h2{
        /* font-size:3vw; */
        /* margin-bottom:1vw; */
    }

    h2 {
        font-size: 1.8rem;
    }

    .footer {
        flex-direction: column;
        gap: 2rem;
    }

    .linksGroup {
        justify-content: center;
        flex-wrap: wrap;
    }
}