.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  /* background: red; */
  position: relative;
  border-radius: var(--border-radius);
  overflow: hidden;
  padding: 2rem;
  gap: .75rem;
  color: white;
  transition: all .5s ease;
  /* border: solid 3px white; */
}

.container:hover {
  box-shadow: .5rem .5rem var(--bright-pink);
  translate: -.5rem -.5rem;
  /* scale: .95; */
}

/* .container[data-isHighlighted=true] {
  grid-column: 1 / -1;
} */

.container::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  inset: 0;
  background: linear-gradient(
    to top,
    #000f,
    #0000
  );
  z-index: -1;
}

.text {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.container img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  top: 0;
  left: 0;
  z-index: -1;
}
.container h1 {
  font-size: 3rem;
  line-height: 1.2em;
}

.container p {
  font-size: 1rem;
}

.highlights {
  display: flex;
  gap: .5rem;
  justify-content: center;
}

.highlight {
  padding: .5rem 1rem;
  font-weight: 600;
  font-size: .75rem;
  background: #6a26cd;
  color: white;
  border-radius: var(--border-radius);
  /* border: solid 2px white; */
  /* box-shadow: 2px 2px white; */
}

@media only screen and (max-width: 800px) {
  .container h1 {
    font-size: 2rem;
  }

  .container {
    gap: 1.5rem;
  }

  .text {
    gap: .5rem;
  }
  
  .container p {
    font-size: .8rem;
  }

  .highlight {
    font-size: .6rem;
  }
}
